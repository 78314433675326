<template>
    <div>
        <div class="card mb-4">
            <div class="card-body">
                <div class="p-4 text-center">
                    <h2 class="mb-4">{{ $t('credits.prefix') }} : {{ creditModif }} €</h2>
                    <div v-if="lastPayment.length > 0">
                        <p v-if="paymentType.type_facturation === 'facture'" class="text-center">
                            <a href="/buy-credits" class="btn btn-secondary btn-lg">
                                {{ $t('credits.buy') }}
                            </a>

                            <a class="btn btn-secondary btn-lg" href="/paiement-accompagnement.php">
                                {{ $t('credits.invoices') }}
                            </a>
                        </p>
                        <p v-else>
                            <a href="/buy-credits" class="btn btn-secondary btn-lg">
                                {{ $t('credits.buy') }}
                            </a>
                        </p>
                    </div>
                    <div v-else>
                        <p>
                            <a href="/buy-credits" class="btn btn-secondary btn-lg">
                                {{ $t('credits.buy') }}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="box">
            <nl-table default-sort="created_at" :columns.sync="columns" :get-data="getData">
                <template #log="{ props }">
                    <span v-html="props.row.log" />
                </template>
            </nl-table>
        </div>
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable'
    import { mapActions } from 'vuex'

    export default {
        title: 'credits.title',
        name: 'Credits',
        components: {
            NlTable
        },
        data() {
            return {
                isLoading: true,
                loading: false,
                creditLog: [],
                userCredit: 0,
                lastPayment: [],
                paymentType: [],
                columns: [
                    {
                        label: 'Date',
                        field: 'created_at',
                        type: 'date',
                        inputType: 'date',
                        centered: false,
                        fieldOption: {
                            dateFormat: 'medium'
                        }
                    },
                    {
                        label: this.$t('credits.event'),
                        field: 'log',
                        centered: false,
                        custom: true
                    },
                    {
                        label: this.$t('credits.amount'),
                        field: 'montant',
                        centered: false
                    }
                ]
            }
        },
        computed: {
            creditModif: function () {
                let num = this.userCredit + ''
                const dec = num.split('.')[1]
                const len = dec && dec.length > 2 ? dec.length : 2
                return Number(num).toFixed(len)
            }
        },
        methods: {
            ...mapActions('auth', {
                getCredits: 'getCredits'
            }),
            getData(params) {
                return new Promise(
                    function (resolve, reject) {
                        this.getCredits(params)
                            .then(data => {
                                this.userCredit = data.user.credit_amount
                                this.lastPayment = data.last_payment
                                this.paymentType = data.payment_type
                                resolve(data.credit_log)
                            })
                            .catch(error => {
                                reject(error)
                            })
                    }.bind(this)
                )
            }
        }
    }
</script>
